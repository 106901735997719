var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "resource-container" },
      [
        _c("div", { staticClass: "section-title" }, [_vm._v("Documents")]),
        _c("DocumentListing", { attrs: { resourceType: _vm.resourceType } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "resource-container" },
      [
        _c("div", { staticClass: "section-title" }, [_vm._v("Videos")]),
        _c("VideoListing", { attrs: { resourceType: _vm.resourceType } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }