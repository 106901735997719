var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "documentSubmission" } }, [
    _c("div", { staticClass: "form-title" }, [_vm._v("Add a Document")]),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "Document Title",
            name: "title",
            required: true,
            placeholder: "Document Title *",
          },
          model: {
            value: _vm.documentObject.title,
            callback: function ($$v) {
              _vm.$set(_vm.documentObject, "title", $$v)
            },
            expression: "documentObject.title",
          },
        }),
        _c("TextBox", {
          staticClass: "textBox description",
          attrs: {
            name: "description",
            required: true,
            type: "textarea",
            placeholder: "Short description *",
          },
          model: {
            value: _vm.documentObject.description,
            callback: function ($$v) {
              _vm.$set(_vm.documentObject, "description", $$v)
            },
            expression: "documentObject.description",
          },
        }),
        _c("file-upload", {
          attrs: { accept: ".pdf" },
          model: {
            value: _vm.file,
            callback: function ($$v) {
              _vm.file = $$v
            },
            expression: "file",
          },
        }),
        _c("ImageCropperForAsset", {
          staticClass: "imagePreviewer",
          attrs: { asset: _vm.documentObject.thumbnailAsset },
          on: { sendImage: _vm.sendImage, uploadImage: _vm.uploadImage },
        }),
        _c("div", [_vm._v("Document Listing Preview")]),
        _c("DocumentPreview", {
          staticClass: "previewBorder",
          attrs: {
            documentObject: _vm.documentObject,
            defaultImage: _vm.imageData,
          },
        }),
        _c("ButtonSubmit", {
          attrs: { copy: "Submit" },
          on: { callback: _vm.submit },
        }),
        _c(
          "button",
          {
            staticClass: "cancel",
            attrs: { type: "button" },
            on: { click: _vm.closeModal },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }