var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "videos-listing" }, [
    _c("div", { staticClass: "viewSelectContainer" }, [
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "addBtn", on: { click: _vm.addVideo } },
            [
              _c("div", { staticClass: "txt" }, [_vm._v("add new video")]),
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fas", "file-plus"] },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "viewSelector" },
        [
          _c("font-awesome-icon", {
            staticClass: "icon list",
            class: { active: _vm.isList },
            attrs: { icon: ["fal", "list"] },
            on: {
              click: function ($event) {
                return _vm.toogleList("list")
              },
            },
          }),
          _c("font-awesome-icon", {
            staticClass: "icon grid",
            class: { active: _vm.isGrid },
            attrs: { icon: ["fa", "th-large"] },
            on: {
              click: function ($event) {
                return _vm.toogleList("grid")
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "box", class: { gridView: _vm.isGrid } },
      [
        _c("LoadingOverlay", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLoader,
              expression: "showLoader",
            },
          ],
        }),
        _vm._l(this.videos, function (video, key) {
          return _c(_vm.toggleListType, {
            key: key,
            tag: "component",
            attrs: { id: video.id, video: video, isAdmin: _vm.isAdmin },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }