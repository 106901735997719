var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "pgTitle" }, [_vm._v("Resources")]),
    _c("div", { staticClass: "resources" }, [
      _c(
        "div",
        { staticClass: "navigation" },
        _vm._l(_vm.activeTabs, function (tab) {
          return _c(
            "button",
            {
              key: tab.name,
              staticClass: "tab",
              class: ["tab", { active: _vm.currentTab === tab.name }],
              attrs: { disabled: tab.enabled == false },
              on: {
                click: function ($event) {
                  return _vm.setCurrentTab(tab.name, tab.resourceType)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(tab.name))])]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "keep-alive",
            [
              _c(_vm.currentTabComponent, {
                tag: "component",
                attrs: { resourceType: _vm.currentResourceType },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }