var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "document" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("div", { staticClass: "thumbnailWrapper" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.downloadDocument.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "thumbnail" },
                [
                  _c("AssetDownload", {
                    attrs: {
                      asset: _vm.documentObject.thumbnailAsset,
                      forceImage: _vm.image,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm.documentObject.Year === 2024
          ? _c("Accordion", {
              staticClass: "mb-4",
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _c("span", { staticClass: "font-semibold text-xl" }, [
                          _vm._v("2024"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "descWrapper" }, [
                          _c(
                            "a",
                            {
                              staticClass: "documentTitle",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.downloadDocument.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\r\n            " +
                                  _vm._s(_vm.documentObject.title) +
                                  "\r\n          "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "documentDescription" }, [
                            _vm._v(
                              "\r\n            " +
                                _vm._s(
                                  _vm._f("truncate")(
                                    _vm.documentObject.description
                                  )
                                ) +
                                "\r\n          "
                            ),
                          ]),
                        ]),
                        _vm.isAdmin
                          ? _c(
                              "div",
                              { staticClass: "deleteDocument" },
                              [
                                _c("ButtonDelete", {
                                  on: { callback: _vm.deleteConfirm },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3107740403
              ),
            })
          : _vm.documentObject.Year === 2023
          ? _c("Accordion", {
              staticClass: "mb-8",
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-semibold text-xl" }, [
                        _vm._v("2023"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "descWrapper" }, [
                        _c(
                          "a",
                          {
                            staticClass: "documentTitle",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.downloadDocument.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\r\n            " +
                                _vm._s(_vm.documentObject.title) +
                                "\r\n          "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "documentDescription" }, [
                          _vm._v(
                            "\r\n            " +
                              _vm._s(
                                _vm._f("truncate")(
                                  _vm.documentObject.description
                                )
                              ) +
                              "\r\n          "
                          ),
                        ]),
                      ]),
                      _vm.isAdmin
                        ? _c(
                            "div",
                            { staticClass: "deleteDocument" },
                            [
                              _c("ButtonDelete", {
                                on: { callback: _vm.deleteConfirm },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            })
          : _vm.documentObject.Year === 2022
          ? _c("Accordion", {
              staticClass: "mb-4",
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("span", { staticClass: "font-semibold text-xl" }, [
                        _vm._v("2022"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "descWrapper" }, [
                        _c(
                          "a",
                          {
                            staticClass: "documentTitle",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.downloadDocument.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\r\n            " +
                                _vm._s(_vm.documentObject.title) +
                                "\r\n          "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "documentDescription" }, [
                          _vm._v(
                            "\r\n            " +
                              _vm._s(
                                _vm._f("truncate")(
                                  _vm.documentObject.description
                                )
                              ) +
                              "\r\n          "
                          ),
                        ]),
                      ]),
                      _vm.isAdmin
                        ? _c(
                            "div",
                            { staticClass: "deleteDocument" },
                            [
                              _c("ButtonDelete", {
                                on: { callback: _vm.deleteConfirm },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            })
          : _c("div", [_vm._v("\r\n      Nothing to see here.\r\n    ")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }