var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "videoModal" } }, [
    _c("div", { staticClass: "top-right" }, [
      _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.closeModal } },
        [_vm._v("\n      X\n    ")]
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "video-frame",
        staticStyle: { padding: "56.25% 0 0 0", position: "relative" },
      },
      [
        _c("iframe", {
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          },
          attrs: {
            src:
              "https://player.vimeo.com/video/" +
              _vm.videoId +
              "?title=0&byline=0&portrait=0",
            frameborder: "0",
            allow: "autoplay; fullscreen",
            allowfullscreen: "",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }