var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "document" }, [
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "thumbnailWrapper" }, [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.download.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "thumbnail" },
              [
                _c("AssetDownload", {
                  attrs: {
                    asset: _vm.documentObject.thumbnailAsset,
                    forceImage: _vm.image,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "descWrapper" }, [
        _c(
          "a",
          {
            staticClass: "documentTitle",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.viewDocument.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\r\n        " + _vm._s(_vm.documentObject.title) + "\r\n      "
            ),
          ]
        ),
        _c("div", { staticClass: "documentDescription" }, [
          _vm._v(
            "\r\n        " +
              _vm._s(_vm._f("truncate")(_vm.documentObject.description)) +
              "\r\n      "
          ),
        ]),
      ]),
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "deleteDocument" },
            [_c("ButtonDelete", { on: { callback: _vm.deleteConfirm } })],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }