var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video" }, [
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "thumbnailWrapper" }, [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.openVideoModal.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "thumbnail" },
              [
                _c("AssetDownload", {
                  attrs: {
                    asset: _vm.video.thumbnailAsset,
                    forceImage: _vm.image,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "descWrapper" }, [
        _c(
          "a",
          {
            staticClass: "videoTitle",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.openVideoModal.apply(null, arguments)
              },
            },
          },
          [_vm._v("\r\n        " + _vm._s(_vm.video.title) + "\r\n      ")]
        ),
        _c("div", { staticClass: "videoDescription" }, [
          _vm._v(
            "\r\n        " +
              _vm._s(_vm._f("truncate")(_vm.video.description)) +
              "\r\n      "
          ),
        ]),
      ]),
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "deleteVideo" },
            [_c("ButtonDelete", { on: { callback: _vm.deleteConfirm } })],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }