var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buttonDelete" },
    [
      _c("font-awesome-icon", {
        staticClass: "icon",
        class: _vm.buttonDisable,
        attrs: { icon: ["fa", "minus-circle"], size: "2x" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.clickedButton.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }