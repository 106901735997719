var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toggle-container", on: { click: _vm.onClick } },
    [
      _vm.isSwitchOn
        ? _c(
            "div",
            { staticClass: "toggle-control" },
            [
              _c("font-awesome-icon", {
                staticClass: "switch-on",
                attrs: { icon: ["fas", "toggle-on"], size: "2x" },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "toggle-control" },
            [
              _c("font-awesome-icon", {
                staticClass: "switch-off",
                attrs: {
                  icon: ["fas", "toggle-on"],
                  size: "2x",
                  rotation: "180",
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }