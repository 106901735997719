var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "videoSubmission" } }, [
    _c("div", { staticClass: "form-title" }, [_vm._v("Add a Video")]),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "Title",
            name: "title",
            required: true,
            placeholder: "Title *",
          },
          model: {
            value: _vm.video.title,
            callback: function ($$v) {
              _vm.$set(_vm.video, "title", $$v)
            },
            expression: "video.title",
          },
        }),
        _c("TextBox", {
          staticClass: "textBox description",
          attrs: {
            name: "description",
            required: true,
            type: "textarea",
            placeholder: "Short description *",
          },
          model: {
            value: _vm.video.description,
            callback: function ($$v) {
              _vm.$set(_vm.video, "description", $$v)
            },
            expression: "video.description",
          },
        }),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "Vimeo Video ID",
            name: "videoId",
            required: true,
            placeholder: "Vimeo Video ID *",
          },
          model: {
            value: _vm.video.videoId,
            callback: function ($$v) {
              _vm.$set(_vm.video, "videoId", $$v)
            },
            expression: "video.videoId",
          },
        }),
        _c("ImageCropperForAsset", {
          staticClass: "imagePreviewer",
          attrs: { asset: _vm.video.thumbnailAsset },
          on: { sendImage: _vm.sendImage, uploadImage: _vm.uploadImage },
        }),
        _c("div", [_vm._v("Resource Library Listing Preview")]),
        _c("VideoPreview", {
          staticClass: "previewBorder",
          attrs: { video: _vm.video, defaultImage: _vm.imageData },
        }),
        _c("ButtonSubmit", {
          attrs: { copy: "Submit" },
          on: { callback: _vm.submit },
        }),
        _c("ButtonCancel", {
          staticClass: "cancel",
          on: { click: _vm.closeModal },
        }),
        _vm.isDeletable
          ? _c("ButtonDeleteFull", {
              staticClass: "delete",
              attrs: { copy: "Delete" },
              on: { callback: _vm.deleteConfirm },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }